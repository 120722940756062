import $ from 'jquery'

$(document).ready(function () {
	const megaMenu = $('.megamenu')
	const metaNavigation = $('.meta-navigation')
	const serviceMenu = $('.a11y-nav.mobile')
	const burger = $('.hamburger-button')
	const level1 = $('.level-1')
	const level2 = $('.level-2')
	const submenuBtn = $('.submenu-btn')
	const header = $('.site-header')

	let b = window.matchMedia('(max-width: 1050px)')
	b.addEventListener('change', handleClickEvent)

	handleClickEvent()

	function handleClickEvent() {
		if (b.matches) {
			level1.on('click', initializeMobile)
		} else {
			level1.off('click', initializeMobile)

			if (header.hasClass('active')) {
				closeMobileMenu()
			}
		}
	}

	function initializeMobile() {
		if ($(this).hasClass('active')) {
			$(this).removeClass('active')
			$(this).find(submenuBtn).removeClass('open')
			$(this).find(level2).attr({ 'aria-expanded': 'false', 'aria-hidden': 'true' })
		} else {
			$(this).addClass('active')
			$(this).find(submenuBtn).addClass('open')
			$(this).find(level2).attr({ 'aria-expanded': 'true', 'aria-hidden': 'false' })
		}
	}

	level1.on('keydown', function (e) {
		let code = e.keyCode ? e.keyCode : e.which
		switch (code) {
			case 40:
				// Down
				if (
					$(this).hasClass('show-submenu') &&
					!$(':focus').parent().next().find('a:first').length &&
					!$(':focus').parent().next('.spacer').length
				) {
					$(this).removeClass('show-submenu')
					$(this).find('.level-2').attr({ 'aria-expanded': 'false', 'aria-hidden': 'true' })

					$(this).next().find('a').first().focus()
				} else if ($(this).hasClass('show-submenu')) {
					if ($(':focus').parent().next('.spacer').length) {
						$(':focus').parent().next().next().find('a').focus()
					} else {
						$(':focus').parent().next().find('a').focus()
					}
				} else {
					$(this).addClass('show-submenu')
					$(this).find('.level-2').attr({ 'aria-expanded': 'true', 'aria-hidden': 'false' })

					$(':focus').parent().next().find('a:first').focus()
				}

				e.preventDefault()
				break
			case 38:
				// Up
				if (
					$(this).hasClass('show-submenu') &&
					!$(':focus').parent().prev().find('a:first').length &&
					!$(':focus').parent().prev('.spacer').length
				) {
					$(this).removeClass('show-submenu')
					$(this).find('.level-2').attr({ 'aria-expanded': 'false', 'aria-hidden': 'true' })

					$(this).find('a').first().focus()
				} else if ($(this).hasClass('show-submenu')) {
					if ($(':focus').parent().prev('.spacer').length) {
						$(':focus').parent().prev().prev().find('a').focus()
					} else {
						$(':focus').parent().prev().find('a').focus()
					}
				} else {
					$(this).prev().find('a').first().focus()
				}

				e.preventDefault()
				break
			case 37:
				// Left
				if ($(this).hasClass('level-1') && !$(this).hasClass('show-submenu')) {
					$(this).prev().find('a').first().focus()
				}
				e.preventDefault()
				break
			case 39:
				// Right
				if ($(this).hasClass('level-1') && !$(this).hasClass('show-submenu')) {
					$(this).next().find('a').first().focus()
				}
				e.preventDefault()
				break
		}
	})

	burger.on('click', function () {
		if (header.hasClass('active')) {
			closeMobileMenu()
		} else {
			openMobileMenu()
		}
	})

	function openMobileMenu() {
		header.addClass('active')
		$('html').addClass('block-scroll')
		megaMenu.attr({ 'aria-expanded': 'true', 'aria-hidden': 'false' })
		metaNavigation.attr({ 'aria-expanded': 'true', 'aria-hidden': 'false' })
		serviceMenu.attr({ 'aria-expanded': 'true', 'aria-hidden': 'false' })
		burger.attr({ 'aria-expanded': 'true' })
	}

	function closeMobileMenu() {
		header.removeClass('active')
		$('html').removeClass('block-scroll')
		megaMenu.attr({ 'aria-expanded': 'false', 'aria-hidden': 'true' })
		metaNavigation.attr({ 'aria-expanded': 'false', 'aria-hidden': 'true' })
		serviceMenu.attr({ 'aria-expanded': 'false', 'aria-hidden': 'true' })
		burger.attr({ 'aria-expanded': 'false' })
		level1.removeClass('active')
		submenuBtn.removeClass('open')
		header.addClass('closing')
	}

	let scrollValueMobile = 0
	let scrollValueDesktop = 0

	function onScrollEvent() {
		if (b.matches) {
			if (window.scrollY > 60) {
				if (window.scrollY > scrollValueMobile) {
					header.removeClass('closing')
					header.removeClass('sticky-mobile')
					scrollValueMobile = window.scrollY
				} else {
					header.addClass('sticky-mobile')
					header.removeClass('initial-delay')
					scrollValueMobile = window.scrollY
				}
			}
		} else {
			if (window.scrollY > 60) {
				header.addClass('sticky-desktop')
				scrollValueDesktop = window.scrollY
			} else {
				header.removeClass('sticky-desktop')
				scrollValueDesktop = window.scrollY
			}
		}
	}

	window.addEventListener('scroll', onScrollEvent)
})
